import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
} from 'react-router-dom';

import {
  PageLogin,
  Page404,
  PageUserHome,
  PageSettings,
  PageOnBoarding,
  PageVirtualCards,
  PageAxiomAI,
  PageVBCWTrans,
  PageRSS,
  PagePromotions,
  PageAnalytics,
  PageMarketingLobby,
  PageMarketingMaterial,
  PageMMApprove,
  PageMyVBC,
  PageGuides,
  PageMyListing,
  PageLeadsLobby,
  PageMySlyDials,
  PageTransactionsLobby,
  PageAllTransactions,
  PageDocuments,
  PageDocumentViewer,
  PageUnusual,
  PagePartiesInvolved,
  PageNotes,
  PageStatus,
  PageEditVBC,
  PageVBCDetails,
  PageCallCenter,
  PageLeadList,
  PageSingleLeadList,
  Feedback,
  Defaults,
  Listing,
  PageAxiomAiScheduler,
  PageRTP,
  DBC,
  CallRep,
  PageBilling,
  PagePairwiseLobby,
  PagePairwisePulse,
  PageTutorials,
  PageConversations,
  PageConversation,
  PageAi,
  PageAiAssistant,
  PageEvents,
  PageAiDistribute,
  PageHomeSearch,
  PageHomeList,
  PageHomeView,
  PageHomePlaces,
  PageHomeListFavorites,
  PageConnectedAccounts,
  PageMarketingMaterialLayouts,
  PageMarketingMaterialSubmit,
  PageAiMediaPosts,
  PageTour,
  PageCardAi,
  PageCardServices,
  PageVBCServices,
  DBCWrapper,
  Profile,
  PageHomePlano,
  PageMarketingMaterialAddData,
} from './pages';

import './App.css';
import Blue from './components/layout/Blue';
import White from './components/layout/White';

import { AuthLayout } from './components/AuthLayout';
import PublicRoutes from './components/PublicRoutes';
import ProtectedRoutes from './components/ProtectedRoutes';
import PageMyReviews from './pages/User/PageMyReviews';
import Settings from './components/pages/Settings';
import { ErrorAuth } from './components/ErrorAuth';
import { VbcLayout } from './components/layout/VbcLayout';
import PageMarketingMaterialSetup from './pages/User/PageMarketingMaterialSetup';
import PageAddOnTour from './pages/tours/PageAddOnTour';
import AdminWrapper from './components/admin/AdminWrapper';
import AdminMarkets from './components/admin/markets/AdminMarkets';
import { AddAnnouncements, AddBuyer, AddHome, AddRatings, BuyerFavorites, BuyerSend, BuyerToursCreate, BuyerToursLobby, BuyerToursWrapper, TourExperienceLobby } from './components/pages/BuyerTours';
import { ClientSearch, InternalFacebook, InternalWrapper } from './components/internal';
import PagePublicWrapper from './pages/Public/PagePublicWrapper';
import PublicWrapper from './pages/Public/PublicWrapper';
import { CalcBuy, CalcExtraPayment, CalcLoanBalance, CalcLobby, CalcSell, CalcSellHold, CalcTitleFees, CalcWrapper } from './components/calculators';
import GlobalWrapper from './components/GlobalWrapper';
import SubNav from './components/SubNav';
import { BrokerCom, ReportsWrapper } from './components/reports';
import { PairwiseAccount, PairwiseDisclosureForm, PairwiseDistributions, PairwiseDocumentsSign, PairwiseDocumentsView, PairwiseEligibility, PairwiseEstimator, PairwiseK1s, PairwisePayments, PairwisePulse } from './components/pages/pairwise';
import { WhatNext, WhatNextClient } from './components/sales';
import PageListingSignup from './pages/tours/PageListingSignup';
import TransactionWrapper from './components/pages/TransactionsLobby/TransactionWrapper';
import { URLViewer } from './components/helpers';
import { AiDaily, AiDailyQuestionsCategory, AiDailyQuestLobby, AiWrapper } from './components/ai_daily';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />} errorElement={<ErrorAuth />}>
      <Route element={<GlobalWrapper />}>
        <Route element={<PublicRoutes />}>
          <Route element={<Blue />}>
            <Route path="/login" element={<PageLogin />} />
          </Route>

          <Route element={<DBCWrapper />}>
            <Route element={<PagePublicWrapper />}>
              <Route path='/card/:id' element={<DBC show_header={false} />} />
            </Route>
            <Route path='card' element={<DBC />} />
            <Route path='card/ai' element={<PageCardAi />} />
            <Route path='card/services' element={<PageCardServices />} />
            {/* <Route path=':id' element={<White />}>
          <Route index element={<DBC />} />
        </Route> */}

            <Route path='homes' element={<VbcLayout />}>
              <Route index element={<PageHomeSearch />} />
              <Route path="list" element={<PageHomeList />} />
              <Route path="favorites" element={<PageHomeListFavorites />} />
              <Route path="view" element={<PageHomeView />} />
              <Route path="places" element={<PageHomePlaces />} />
              <Route path="plano" element={<PageHomePlano />} />
            </Route>

            <Route path='/user' element={<div className=' font-base text-sm text-gray-500 h-full rounded-2xl max-w-[375px] mx-auto bg-user-wrapper relative '>
              <div className='p-[10px]'><Outlet /></div>
            </div>}>
              <Route path="url_viewer" element={<URLViewer />} />
              <Route path="events" element={<PageEvents />} />
              <Route path='listing_tours' element={<Outlet />}>
                <Route index element={<PageTour />} />
                <Route path="add" element={<PageAddOnTour />} />
                <Route path="signups" element={<PageListingSignup />} />
              </Route>

              <Route path='calculators' element={<CalcWrapper />}>
                <Route index element={<CalcLobby />} />
                <Route path='buy' element={<CalcBuy implementation_type='buy' />} />
                <Route path='loan_compare' element={<CalcBuy implementation_type='loan_compare' />} />
                <Route path='loan_details' element={<CalcBuy implementation_type='loan_details' />} />
                <Route path='affordability' element={<CalcBuy implementation_type='affordability' />} />
                <Route path='rent_vs_buy' element={<CalcBuy implementation_type='rent_vs_buy' />} />
                <Route path='sell' element={<CalcSell implementation_type='sell' />} />
                <Route path='sell_to_net' element={<CalcSell implementation_type='sell_to_net' />} />
                <Route path='multiple_offers' element={<CalcSell implementation_type='multiple_offers' />} />
                <Route path='title_fees' element={<CalcTitleFees type='alone' />} />
                <Route path='holding_costs' element={<CalcSellHold type='alone' />} />
                <Route path='extra_payment' element={<CalcExtraPayment type='alone' />} />
                <Route path='loan_balance' element={<CalcLoanBalance type='alone' />} />
              </Route>

              <Route
                path="marketing-material/add-data"
                element={<PageMarketingMaterialAddData />}
              />

              <Route path='reports' element={<ReportsWrapper />}>
                <Route path='broker_com' element={<BrokerCom />} />
              </Route>

              <Route path='ai' element={<Outlet />}>
                <Route index element={<PageAi />} />
                <Route path="assistant" element={<PageAiAssistant />} />
                <Route path='media_posts' element={<PageAiMediaPosts />} />

              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<ProtectedRoutes />}>
          {/* <Route element={<Blue />}> */}
          <Route path="" element={<PageOnBoarding />} />
          {/* </Route> */}



          <Route path="/user" element={<White />}>
            {/*  */}
            <Route index element={<PageUserHome />} />

            <Route path='admin' element={<AdminWrapper />}>
              <Route index element={<div></div>}></Route>
              <Route path='markets' element={<AdminMarkets />}></Route>
            </Route>


            <Route path='internal' element={<InternalWrapper />}>
              <Route index element={<div></div>}></Route>
              <Route path='client/search' element={<ClientSearch type='no_swipe' />}></Route>
              <Route path='facebook' element={<InternalFacebook />}></Route>
            </Route>

            <Route path='sales' element={<InternalWrapper />}>
              <Route index element={<div></div>}></Route>
              <Route path='what_next' element={<WhatNext />}></Route>
              <Route path='what_next/client' element={<WhatNextClient />}></Route>
            </Route>


            <Route path='buyer_tours' element={<BuyerToursWrapper />}>
              <Route index element={<BuyerToursLobby />}></Route>
              <Route path='create' element={<BuyerToursCreate />}></Route>
              <Route path='add_buyer' element={<AddBuyer />}></Route>
              <Route path='add_home' element={<AddHome />}></Route>
              <Route path='send_to_buyers' element={<BuyerSend />}></Route>
              <Route path='announcements' element={<AddAnnouncements />}></Route>
              <Route path='ratings' element={<AddRatings />}></Route>
              <Route path='buyer_favorites' element={<BuyerFavorites />}></Route>
              <Route path='tour_experience_lobby' element={<TourExperienceLobby />}></Route>
            </Route>

            <Route path='ai/daily' element={<AiWrapper />}>
              <Route index element={<AiDaily />}></Route>
              <Route path='questions' element={<AiDailyQuestLobby />}></Route>
              <Route path='questions/:cat_id' element={<AiDailyQuestionsCategory />}></Route>
            </Route>

            <Route path="conversations" element={<Outlet />}>
              <Route index element={<PageConversations />} />
              <Route path=':id' element={<PageConversation />} />
            </Route>

            <Route path="settings" element={<Outlet />}>
              <Route index element={<PageSettings />} />
              <Route path='call-team' element={<PageCallCenter />} />
              <Route path='connected-accounts' element={<PageConnectedAccounts />} />
              <Route path='lead-list' element={<Outlet />}>
                <Route index element={<PageLeadList />} />
                <Route path=':id' element={<PageSingleLeadList />} />
              </Route>
            </Route>
            <Route path="my-profile" element={<Settings />} />
            <Route path="automations" element={
              // <div className='font-base text-sm text-gray-500 overflow-hidden rounded-2xl mx-auto bg-user-wrapper min-h-[100vh] relative p-3'>
              <div className=' bg-white rounded-lg h-full'>
                <SubNav />
                <Profile type='automations' title="Automations" subtitle={'Configure your AXIOM automations here.'} />
              </div>
              // </div>
            } />
            <Route path="defaults" element={<Defaults />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="billing" element={<PageBilling />} />
            <Route path="tutorials" element={<PageTutorials />} />
            <Route path="axiom-ai" element={<PageAxiomAI />} />
            <Route path="axiom-ai-distribute" element={<PageAiDistribute />} />
            <Route path="axiom-ai-scheduler" element={<PageAxiomAiScheduler />} />
            <Route path="vbc" element={<PageVBCWTrans />} />
            <Route path="rss-feed" element={<PageRSS />} />
            <Route path="promotions" element={<PagePromotions />} />
            <Route path="analytics" element={<PageAnalytics />} />
            <Route path="marketing-lobby" element={<PageMarketingLobby />} />
            <Route
              path="marketing-material"
              element={<PageMarketingMaterial />}
            />

            <Route
              path="marketing-material/layouts"
              element={<PageMarketingMaterialLayouts />}
            />
            <Route
              path="marketing-material/layouts/customize"
              element={<PageMarketingMaterialSetup />}
            />
            <Route
              path="marketing-material/layouts/confirm"
              element={<PageMarketingMaterialSubmit />}
            />
            <Route
              path="approve-marketing-material"
              element={<PageMMApprove />}
            />
            <Route path="marketing-call" element={<CallRep />} />

            <Route path='pairwise' element={<ReportsWrapper />}>
              <Route index element={<PagePairwiseLobby />} />
              <Route path="pulse-report" element={<PairwisePulse />} />
              <Route path="documents/view" element={<PairwiseDocumentsView />} />
              <Route path="disclosure-form" element={<PairwiseDisclosureForm />} />
              <Route path="eligibility" element={<PairwiseEligibility />} />
              <Route path="estimator" element={<PairwiseEstimator />} />
              <Route path="distributions" element={<PairwiseDistributions />} />
              <Route path="k1s" element={<PairwiseK1s />} />
              <Route path="account" element={<PairwiseAccount />} />
              <Route path="documents/sign" element={<PairwiseDocumentsSign />} />
              <Route path="payments" element={<PairwisePayments />} />
            </Route>

            <Route path="my-vbc">
              <Route index element={<PageMyVBC />} />
              <Route path=":vbcid" element={<PageVBCDetails />} />
              <Route path="services" element={<PageVBCServices />} />
            </Route>
            <Route path="virtual-cards">
              <Route index element={<PageVirtualCards />} />
              <Route path="edit/:vbcid" element={<PageEditVBC />} />
            </Route>
            <Route path="guides" element={<PageGuides />} />
            <Route path="reviews" element={<PageMyReviews />} />
            <Route path="my-listing" element={<Outlet />}>
              <Route index element={<PageMyListing />} />
              <Route path=':id' element={<Listing />} />
            </Route>
            <Route path="my-leads-lobby" element={<Outlet />}>
              <Route index element={<PageLeadsLobby />} />
              <Route path=":id" element={<PageSingleLeadList />} />
            </Route>
            <Route path="my-slydials" element={<PageMySlyDials />} />

            <Route path="my-profile" element={<Settings />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="my-transactions-lobby">
              <Route index element={<PageTransactionsLobby />} />
              <Route path="transactions">
                <Route index element={<PageAllTransactions />} />
                <Route element={<TransactionWrapper />}>
                  <Route path=":fileid/documents" element={<PageDocuments />} />
                  <Route path=":fileid/title" element={<PageUnusual />} />
                  <Route path=":fileid/parties" element={<PagePartiesInvolved />} />
                  <Route path=":fileid/notes" element={<PageNotes />} />
                  <Route path=":fileid/statuses" element={<PageStatus />} />
                </Route>
                <Route
                  path=":fileid/documents/:documentId"
                  element={<PageDocumentViewer />}
                />
              </Route>
              <Route path="rtp" element={<PageRTP />} />
            </Route>

          </Route>
        </Route>

        <Route path="*" element={<Page404 />}></Route>
      </Route>
    </Route>
  )
);
