import React from 'react'
import { useAiDaily } from '../../contexts/AiDailyContext'

const AiDailyProgressText = ({ cat }) => {
  const { get_cat_tot_quest, get_cat_tot_ans, get_cat_per_ans } = useAiDaily()
  return (
    <div>
      {' '}
      <div className='flex flex-col items-center'>
        <div className='text-xs'>{get_cat_per_ans(cat)}%</div>
        <div className='text-xs'>
          {get_cat_tot_ans(cat)}/{get_cat_tot_quest(cat)}
        </div>
      </div>
    </div>
  )
}

export default AiDailyProgressText
