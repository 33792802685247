import React from 'react'
import { useAiDaily } from '../../contexts/AiDailyContext'
import { validate_number } from '../../helpers'
import ProgressBarChildren from '../ui/ProgressBarChildren'
import uiStyles from '../ui/uiStyles'
import { FiArrowRightCircle } from 'react-icons/fi'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import AiDailyProgressText from './AiDailyProgressText'
const AiDailyQuestLobby = () => {
  const {
    aiDaily,
    saveAiDaily,
    get_cat_per_ans,
    get_tot_ans,
    get_tot_quest,
    get_tot_per,
  } = useAiDaily()
  const navigate = useNavigate()
  console.log(aiDaily)
  return (
    <div className='flex flex-col justify-between h-full'>
      <div>
        <div>
          <div className='flex justify-center'>
            <ProgressBarChildren
              stringId={'quest_progress'}
              percentage={get_tot_per()}
              width={100}
            >
              <div className='flex flex-col items-center'>
                <div className='text-xs'>{get_tot_per()}%</div>
                <div className='text-xs'>
                  {get_tot_ans()} of {get_tot_quest()}
                </div>
              </div>
            </ProgressBarChildren>
          </div>
        </div>
        <div className='px-3 mt-[10px] flex flex-col gap-[5px]'>
          {aiDaily?.data?.bio_cat?.map((item) => {
            const total_answers = item?.questions?.filter(
              (item) => item?.answer
            )?.length
            const total_questions = item?.questions?.length
            const percentage = validate_number(total_answers / total_questions)
            return (
              <div
                id={`cat__${item.cat_uuid}`}
                className={`${uiStyles.card_gray_gradient} flex justify-between cursor-pointer `}
                onClick={() => {
                  return navigate(`${item.cat_id}`)
                }}
              >
                <div className='flex gap-[10px] items-center'>
                  <ProgressBarChildren
                    stringId={`cat_${item.cat_uuid}`}
                    percentage={get_cat_per_ans(item)}
                    width={60}
                  >
                    {' '}
                    <AiDailyProgressText cat={item} />
                  </ProgressBarChildren>
                  <p className={`${uiStyles.text_black_subtitle} capitalize`}>
                    {item?.cat_name}
                  </p>
                </div>
                <div className={`${uiStyles.text_black} flex items-center`}>
                  <FiArrowRightCircle className='w-[25px] h-[25px]' />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='p-3 flex justify-end gap-3'>
        <button
          className={uiStyles.white_btn}
          onClick={() => {
            navigate(-1)
          }}
        >
          Back To Post
        </button>
        {/* <button
          className={uiStyles.hover_btn}
          onClick={() => {
            navigate(-1)
          }}
        >
          Regenerate Prompt
        </button> */}
      </div>
    </div>
  )
}

export default AiDailyQuestLobby
