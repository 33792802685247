import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Client } from '@twilio/conversations'
import { useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { useProfile } from '../../contexts/UserContext'
import { ServiceProfile } from '../../services/ServiceProfile'
import ErrorBoundary from '../ErrorBoundy'
import { useTransactions } from '../../contexts/TransactionsContext'
import { ServiceTransactions } from '../../services/ServiceTransactions'
import Loading from '../Loading'
import { useReviews } from '../../contexts/ReviewsContext'
import { useListing } from '../../contexts/ListingContext'
import { ServiceClientListing } from '../../services/ServiceClientListing'
import { ServiceClientReviews } from '../../services/ServiceClientReviews'
import { useLimits } from '../../contexts/LimitsContext'
import useAxiomFetch from '../../hooks/useAxiomFetch'
import SmallLoading from '../ui/SmallLoading'
import { useConnections } from '../../contexts/ConnectionContext'
import { useTotals } from '../../contexts/TotalsContext'
import { useConversations } from '../../contexts/ConversationsContext'
import { useClaims } from '../../contexts/ClaimsContext'
import { useAi } from '../../contexts/AiContext'
import jwtDecode from 'jwt-decode'
import { useMktReqs } from '../../contexts/MktReqsContext'
import { useListingsSold } from '../../contexts/ListingsSoldContext'
import { useDistribute } from '../../contexts/DistributeContext'
import {
  date_within_days,
  get_storage,
  just_listed_days,
  listing_status,
  set_storage,
  is_json,
} from '../../helpers'
import { useListingTours } from '../../contexts/ListingToursContext'
import { compressToUTF16, decompressFromUTF16 } from 'lz-string'
import { useDefaults } from '../../contexts/DefaultsContext'
import { useVBCs } from '../../contexts/VBCContext'

export default function White({}) {
  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1

  if (localStorage.getItem('add_homescreen') !== 'done') {
    if (!isSafari) {
      localStorage.setItem('add_home_screeen', 'later')
    } else {
      localStorage.removeItem('add_homesceen')
    }
  }

  // eslint-disable-next-line
  if (!Array.prototype.findLastIndex) {
    Array.prototype.findLastIndex = function (callback, thisArg) {
      for (let i = this.length - 1; i >= 0; i--) {
        if (callback.call(thisArg, this[i], i, this)) return i
      }
      return -1
    }
  }

  const { axiomFetch } = useAxiomFetch()
  const [reloadConvs, setReloadConvs] = useState(0)
  const { profile, saveProfile } = useProfile()
  const { claims, saveClaims } = useClaims()
  const { saveAi } = useAi()
  const { defaults, saveDefaults } = useDefaults()
  const { limits, saveLimits } = useLimits()
  const { totals, saveTotals } = useTotals()
  const { transactions, saveTransactions } = useTransactions()
  const { reviews, saveReviews } = useReviews()
  const { listing, saveListing } = useListing()
  const { listingsSold, saveListingsSold } = useListingsSold()
  const { distribute, saveDistribute } = useDistribute()
  const { mktReqs, saveMktReqs } = useMktReqs()
  const { listingTours, saveListingTours } = useListingTours()
  const { saveVBCs, VBCs } = useVBCs()
  const {
    conversations,
    saveConversations,
    saveCampaigns,
    saveConvMessages,
    convMessages,
    token,
    saveToken,
    saveLoadingConversations,
    loadingConversations,
  } = useConversations()
  const { connections, saveConnections } = useConnections()
  const { getAccessTokenSilently } = useAuth0()

  const profilefetchController = useRef(null)
  const transactionsfetchController = useRef(null)
  const listingfetchController = useRef(null)
  const reviewsfetchController = useRef(null)

  const params = useMemo(() => {
    return {
      skip: 0,
      limit: 5,
    }
  }, [])

  // const getTransactions = useCallback(async () => {
  //   const token = await getAccessTokenSilently({
  //     audience: process.env.REACT_APP_AUDIENCE,
  //   })

  //   const serviceTransactions = new ServiceTransactions(token)
  //   if (!transactionsfetchController.current) {
  //     transactionsfetchController.current =
  //       serviceTransactions.getController().controller
  //   }
  //   return serviceTransactions.getAll()
  // }, [getAccessTokenSilently])

  // const getListing = useCallback(async () => {
  //   const token = await getAccessTokenSilently({
  //     audience: process.env.REACT_APP_AUDIENCE,
  //   }).catch(err => null)

  //   const serviceClientListing = new ServiceClientListing(token)
  //   if (!listingfetchController.current) {
  //     listingfetchController.current =
  //       serviceClientListing.getController().controller
  //   }
  //   return serviceClientListing.getAll(params)
  // }, [getAccessTokenSilently, params])

  const getReviews = useCallback(async () => {
    const token = await getAccessTokenSilently({
      audience: process.env.REACT_APP_AUDIENCE,
    }).catch((err) => null)

    const serviceClientReviews = new ServiceClientReviews(token)
    if (!reviewsfetchController.current) {
      reviewsfetchController.current =
        serviceClientReviews.getController().controller
    }
    return serviceClientReviews.getAll(params)
  }, [getAccessTokenSilently, params])

  // const transactionsQuery = useQuery({
  //   queryKey: ['alltransactions'],
  //   queryFn: getTransactions,
  //   refetchOnWindowFocus: false,
  //   enabled: !!profile && !transactions,
  // })

  // const listingQuery = useQuery({
  //   queryKey: ['listing', params],
  //   queryFn: getListing,
  //   refetchOnWindowFocus: false,
  //   enabled: !!profile && !listing,
  // })

  const reviewsQuery = useQuery({
    queryKey: ['reviews', params],
    queryFn: getReviews,
    refetchOnWindowFocus: false,
    enabled: !!profile && !reviews,
  })

  useEffect(() => {
    const get_profile = async () => {
      const save_admin = (data) => {
        if (data?.DEPARTMENT?.toLowerCase() === 'admin') {
          data.is_admin = window?.localStorage?.getItem('is_admin')
            ? window?.localStorage?.getItem('is_admin')
            : 'Y'
        } else {
          data.is_admin = 'N'
        }
        return data
      }

      if (get_storage('profile')) {
        const profile_data = get_storage('profile')
        const result_data = save_admin(profile_data)

        saveProfile((prev) => ({ ...prev, ...get_storage(result_data) }))
      }

      if (get_storage('claims')) {
        saveClaims((prev) => ({ ...prev, ...get_storage('claims') }))
      }

      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUDIENCE,
      }).catch((err) => null)
      const claims = jwtDecode(token)
      if (claims?.user_id) {
        claims.is_internal = window?.localStorage?.getItem('is_internal')
          ? window?.localStorage?.getItem('is_internal')
          : 'Y'
      } else {
        claims.is_internal = 'N'
      }
      saveClaims((prev) => {
        set_storage('claims', { ...prev, ...claims })
        return { ...prev, ...claims }
      })
      saveAi((prev) => ({
        ...prev,
        assistant: {
          ...prev?.assistant,
          is_assistant:
            window?.localStorage?.getItem('ai_assistant') ||
            (claims?.cont_id === 535922 ? 'N' : 'Y'),
        },
      }))

      const data = (await axiomFetch('/client/profile'))?.[0]
      if (data?.CONT_ID) {
        const data_result = save_admin(data)
        saveProfile((prev) => {
          set_storage('profile', { ...prev, ...data_result })
          return { ...prev, ...data_result }
        })
      }
    }
    get_profile()
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const upd_data = async () => {
      await axiomFetch(
        '/armls/view/update_mls',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
    }
    upd_data()

    return () => {
      controller?.abort()
    }
  }, [])

  // useEffect(() => {
  //   if (!transactions) {
  //     if (transactionsQuery.data && transactionsQuery.data.length) {
  //       saveTransactions(transactionsQuery.data)
  //     }
  //   }

  //   return () =>
  //     transactionsfetchController.current &&
  //     transactionsfetchController.current.abort()
  // }, [transactionsQuery.data, saveTransactions, transactions])

  useEffect(() => {
    const controller = new AbortController()
    const get_data = async () => {
      const data = await axiomFetch(
        '/escrow/transaction',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (data?.name === 'AbortError') {
        return
      }
      console.log('escrow_data', data)
      if (!transactions) {
        saveTransactions(data)
      }
    }

    get_data()
    return () => controller?.abort()
  }, [])

  // useEffect(() => {
  //   if (!listing) {
  //     if (listingQuery.data && listingQuery.data.length) {
  //       saveListing(listingQuery.data)
  //       saveMktReqs((prev) => ({
  //         ...prev,
  //         listings: listingQuery.data,
  //         current_listing: listingQuery?.data?.[0],
  //       }))
  //       saveAi((prev) => ({
  //         ...prev,
  //         listings: listingQuery?.data || [],
  //         current_listing: listingQuery?.data?.[0],
  //       }))
  //     }
  //   }

  //   return () =>
  //     listingfetchController.current && listingfetchController.current.abort()
  // }, [listingQuery.data, saveListing, listing])

  useEffect(() => {
    if (!reviews) {
      if (reviewsQuery.data && reviewsQuery.data.length) {
        saveReviews(reviewsQuery.data)
      }
    }

    return () =>
      reviewsfetchController.current && reviewsfetchController.current.abort()
  }, [reviewsQuery.data, saveReviews, reviews])

  // useEffect(() => {
  //   const controller = new AbortController()
  //   const get_listings = async () => {
  //     const save_listings = (data) => {
  //       saveListing(data)
  //       saveMktReqs((prev) => ({
  //         ...prev,
  //         listings: data,
  //       }))
  //       saveAi((prev) => ({
  //         ...prev,
  //         listings: data,
  //       }))
  //     }
  //     let stored_profile
  //     if (get_storage('account')) {
  //       if (get_storage('profile')) {
  //         stored_profile = get_storage('profile') || {}
  //       }
  //       saveProfile((prev) => ({
  //         ...prev,
  //         ...stored_profile,
  //         account: get_storage('account'),
  //       }))
  //     }
  //     if (get_storage('all_listings')) {
  //       save_listings(get_storage('all_listings'))
  //     }
  //     const initial_data = await axiomFetch(
  //       '/marketing/homes/get_listings?type=agent',
  //       'GET'
  //     )
  //     save_listings(initial_data)

  //     let data = await axiomFetch(
  //       '/armls/view/listings',
  //       'GET',
  //       {},
  //       {},
  //       false,
  //       controller.signal
  //     )
  //     if (data?.name === 'AbortError') {
  //       return
  //     }
  //     const profile_data = await axiomFetch('/client/profile')
  //     if (!controller.signal.aborted) {
  //       save_listings(data?.listings)
  //       if (profile_data?.CONT_ID) {
  //         saveProfile((prev) => {
  //           set_storage('profile', {
  //             ...prev,
  //             ...profile_data[0],
  //             account: data?.account,
  //             is_admin: prev?.is_admin,
  //           })
  //           return {
  //             ...prev,
  //             ...profile_data[0],
  //             account: data?.account,
  //             is_admin: prev?.is_admin,
  //           }
  //         })
  //       }
  //       set_storage('account', data?.account)
  //       set_storage('all_listings', data?.listings)
  //     }
  //   }

  //   get_listings()

  //   return () => {
  //     controller?.abort()
  //   }
  // }, [])
  useEffect(() => {
    const controller = new AbortController()
    const get_defaults = async () => {
      if (!Object.keys(defaults)?.length) {
        const save_defaults = (data) => {
          saveDefaults(data)
        }
        if (get_storage('defaults')) {
          saveDefaults(get_storage('defaults'))
        }
        let data = await axiomFetch(
          '/lobbies/defaults',
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        if (!controller.signal.aborted) {
          save_defaults(data?.defaults?.[0] || {})
          set_storage('defaults', data?.defaults?.[0] || {})
        }
      }
    }
    get_defaults()

    return () => {
      controller?.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_vbcs = async () => {
      if (!Object.keys(VBCs)?.length) {
        const save_vbcs = (data) => {
          saveVBCs(data)
        }
        if (get_storage('vbcs')) {
          saveVBCs(get_storage('vbcs'))
        }
        let data = await axiomFetch(
          '/products/vbc',
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        if (!controller.signal.aborted) {
          save_vbcs(data)
          set_storage('vbcs', data || {})
        }
      }
    }
    get_vbcs()

    return () => {
      controller?.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_limits = async () => {
      if (!limits?.length) {
        const limits = await axiomFetch(
          '/marketing/content/limits',
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        if (!controller.signal.aborted) {
          saveLimits(limits)
        }
      }
    }
    get_limits()

    return () => {
      controller?.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_totals = async () => {
      if (!totals) {
        const totals = await axiomFetch(
          '/marketing/content/totals',
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        saveTotals(totals)
      }
    }
    get_totals()

    return () => {
      controller?.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_connections = async () => {
      if (!connections) {
        const all_connections = await axiomFetch(
          '/usage/checkSocialConnections',
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        saveConnections((prev) => ({ ...prev, ...all_connections }))
      }
    }
    get_connections()

    return () => {
      controller?.abort()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const get_materials = async () => {
      if (get_storage('market_materials')) {
        saveMktReqs((prev) => ({
          ...prev,
          materials: get_storage('market_materials') || {},
        }))
      }
      const materials = await axiomFetch(
        '/marketing/materials/slot_groups',
        'GET',
        {},
        {},
        false,
        controller.signal
      )
      if (materials.name === 'AbortError') {
        return
      }
      // console.log('materials', materials)
      const get_weekenders = () => {
        const weekenders = materials?.mkt_req_weekend_img
        const group =
          (materials?.mkt_slot_group || [])?.find(
            (group) => group.group_id === 61
          ) || {}
        const weekender_flyer = materials?.mkt_slot_group
          ?.find((group) => group.group_id === 61)
          ?.items?.find(
            (single_layout) => single_layout?.single_layout_id === 241
          )
        const sub_items = weekenders?.map((item) => {
          weekender_flyer.single_layout = {
            ...(weekender_flyer?.single_layout || {}),
            ...item,
          }
          return { ...weekender_flyer, ...item }
        })
        group.items = sub_items?.sort((a, b) => a?.loc_id - b?.loc_id)
        return group
      }
      materials.weekenders = get_weekenders() || []
      materials.mkt_slot_group = [
        ...(materials?.mkt_slot_group || [])?.filter(
          (item) => item.group_id !== 61
        ),
        get_weekenders(),
      ]
      console.log('materials', materials)
      if (materials?.mkt_req_layout?.length) {
        set_storage('market_materials', materials)
        saveMktReqs((prev) => ({ ...prev, materials }))
      }
    }

    get_materials()

    return () => {
      controller?.abort()
    }
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   const controller = new AbortController()
  //   const controller_2 = new AbortController()
  //   const get_data = async () => {
  //     let tour_state = {}
  //     let buyer_tours = {}
  //     let events = {}
  //     if (get_storage('listing_tours_state')) {
  //       tour_state = get_storage('listing_tours_state')
  //       saveListingTours((prev) => ({ ...prev, tour_state }))
  //     }
  //     if (get_storage('buyer_tours')) {
  //       buyer_tours = get_storage('buyer_tours')
  //       saveListingTours((prev) => ({ ...prev, buyer_tours }))
  //     }
  //     if (get_storage('tour_events')) {
  //       events = get_storage('tour_events')
  //       saveListingTours((prev) => ({ ...prev, events }))
  //     }
  //     if (get_storage('listing_tours')) {
  //       saveListingTours((prev) => ({
  //         ...prev,
  //         data: get_storage('listing_tours') || {},
  //         tour_state,
  //       }))
  //     }
  //     const data = await axiomFetch(
  //       '/tours/data',
  //       'GET',
  //       {},
  //       {},
  //       false,
  //       controller.signal
  //     )

  //     if (data?.name === 'AbortError') {
  //       return
  //     }
  //     console.log({ second_tour_data: data })
  //     // const ratings = await axiomFetch(
  //     //   '/tours/start',
  //     //   'POST',
  //     //   { event_id: data?.event_pages?.[0]?.event_id || null },
  //     //   {},
  //     //   false,
  //     //   controller.signal
  //     // )

  //     // if (ratings?.length) {
  //     //   data.tour_ratings = ratings
  //     // }
  //     if (data?.event_tour_info?.length && data?.mls_listings_v?.length)
  //       data?.event_tour_info?.map((item) => {
  //         item.details =
  //           data?.mls_listings_v?.find(
  //             (mls) => item.mls_num?.toString() === mls?.mls_id?.toString()
  //           ) || {}
  //         return item
  //       })

  //     if (data?.tour_types?.[0]?.type_id) {
  //       if (!tour_state?.event_id) {
  //         tour_state.event_id = data?.event_pages?.[0]?.event_id
  //         tour_state.completed = data?.event_pages?.[0]?.tour_complete
  //       }
  //       tour_state.completed =
  //         data?.event_pages?.find(
  //           (item) => item?.event_id === tour_state?.event_id
  //         )?.tour_complete || 0

  //       data?.axiom_tours_timers?.forEach((timer) => {
  //         events[timer?.event_id] = {
  //           ...(events?.[timer?.event_id] || {}),
  //           [timer?.mls_id]: {
  //             run: timer?.run,
  //             start_time: timer?.start_timestamp,
  //             curr_time: timer?.total_seconds,
  //           },
  //         }
  //       })
  //       set_storage('listing_tours_state', tour_state)
  //       set_storage('listing_tours', data)
  //       set_storage('buyer_tours', buyer_tours)
  //       set_storage('tour_events', events)
  //       saveListingTours((prev) => ({
  //         ...prev,
  //         data,
  //         tour_state,
  //         buyer_tours,
  //         events,
  //       }))
  //     }
  //   }
  //   get_data()

  //   return () => {
  //     controller?.abort()
  //     controller_2?.abort()
  //   }
  //   // eslint-disable-next-line
  // }, [])

  // useEffect(() => {
  //   const host = process.env.REACT_APP_API_ROOT.replace(/^http/, 'ws')
  //   let ws
  //   if (ws === undefined || ws?.readyState === 3) {
  //     ws = new WebSocket(host)
  //   }
  //   const connect_socket = () => {
  //     ws.onmessage = function (event, isBinary) {
  //       let check_json = is_json(event?.data)
  //       let msg = check_json ? JSON.parse(event.data) : event.data
  //       console.log(msg)
  //       if (check_json) {
  //         if (msg?.meta_data?.type === 'tour_timer') {
  //           saveListingTours((prev) => {
  //             const events = {
  //               ...(prev?.events || {}),
  //               [msg?.data?.event_id]: {
  //                 ...prev?.events?.[msg?.data?.event_id],
  //                 [msg?.data?.mls_num]: {
  //                   start_time: msg?.data?.start_time,
  //                   curr_time: msg?.data?.curr_time,
  //                   run: msg?.data?.run,
  //                 },
  //               },
  //             }
  //             set_storage('tour_events', events)
  //             return {
  //               ...prev,
  //               events,
  //             }
  //           })
  //         }
  //       }
  //     }
  //     ws.onopen = (event) => {
  //       ws.send('hey everyone')
  //       saveClaims((prev) => ({ ...prev, ws }))
  //     }

  //     ws.onerror = () => {
  //       console.log('socket error')
  //       if (ws) {
  //         ws.close()
  //       }
  //     }
  //     ws.onclose = (event) => {
  //       console.log('disconnected client')
  //       if (ws) {
  //         ws.close()
  //         setTimeout(() => {
  //           ws = new WebSocket(host)
  //           connect_socket()
  //         }, 2000)
  //       }
  //     }
  //   }
  //   connect_socket()
  //   return () => {
  //     ws.close()
  //     ws = undefined
  //   }
  // }, [])

  // useEffect(() => {
  //   const controller = new AbortController()
  //   const init_conversations = async () => {
  //     const token = await getAccessTokenSilently().catch((err) => null)
  //     const claims = jwtDecode(token)

  //     const data = await axiomFetch(
  //       '/marketing/conversations/token',
  //       'POST',
  //       {
  //         identity: claims?.cont_id?.toString(),
  //       },
  //       {},
  //       false,
  //       controller.signal
  //     )
  //     saveToken(data?.token)

  //     if (data?.token) {
  //       window.client = Client
  //       const client = new Client(data?.token)
  //       client.on('connectionStateChanged', (state) => {
  //         if (state === 'connecting') {
  //           console.log('connecting client')
  //         }
  //         if (state === 'connected') {
  //           console.log(loadingConversations)
  //           setTimeout(() => {
  //             saveLoadingConversations(false)
  //           }, [3000])
  //           console.log('connected')
  //         }
  //         if (state === 'disconnecting') {
  //           setReloadConvs((prev) => prev + 1)
  //           console.log('disconnecting')
  //         }
  //         if (state === 'disconnected') {
  //           console.log('disconnected')
  //         }
  //         if (state === 'denied') {
  //           console.log('denied')
  //         }
  //       })
  //       let all_conversations = []
  //       client.on('conversationJoined', async (conversation) => {
  //         if (loadingConversations) {
  //           setTimeout(() => {
  //             saveLoadingConversations(false)
  //           }, [3000])
  //         }
  //         if (
  //           conversations.filter((item) => item.sid === conversation?.sid)
  //             .length === 0 &&
  //           all_conversations.filter((item) => item.sid === conversation?.sid)
  //             .length === 0
  //         ) {
  //           all_conversations.push(conversation)
  //           let campaign = conversation?.attributes?.campaign || ''
  //           // if (campaign) {
  //           //   saveCampaigns((prev) => ({
  //           //     ...prev,
  //           //     [campaign]: {
  //           //       name: campaign,
  //           //       conversations: [
  //           //         ...(prev?.[campaign]?.conversations || []),
  //           //         conversation,
  //           //       ],
  //           //     },
  //           //   }))
  //           // } else {
  //           saveConversations((prev) => [...prev, conversation])
  //           // }

  //           const messages = await conversation.getMessages()
  //           let unread_count = 0
  //           let date = conversation?.lastMessage?.dateCreated
  //           let last_msg = messages?.items?.filter(
  //             (item) => item.state.index === conversation?.lastMessage?.index
  //           )[0]
  //           await conversation.advanceLastReadMessageIndex(
  //             conversation?.attributes?.start_index > 0
  //               ? conversation?.attributes?.start_index
  //               : 0
  //           )
  //           unread_count = await conversation.getUnreadMessagesCount()
  //           saveConvMessages((prev) => ({
  //             ...prev,
  //             [conversation?.sid]: {
  //               ...prev?.[conversations?.sid],
  //               messages,
  //               body: last_msg?.state.body,
  //               last_msg_author: last_msg?.state?.author,
  //               unread_count,
  //               date,
  //               last_msg,
  //             },
  //           }))

  //           conversation.on('messageAdded', async (message) => {
  //             if (
  //               message?.state?.author?.toString() ===
  //               claims?.cont_id?.toString()
  //             ) {
  //               console.log('hello')
  //               await conversations?.[
  //                 message?.conversation?.sid
  //               ]?.setAllMessagesRead()
  //             }
  //             const all_urls =
  //               (await Promise?.all(
  //                 message?.state?.medias?.map(async (url) => {
  //                   return await url?.getContentTemporaryUrl()
  //                 }) || []
  //               )) || []

  //             saveConvMessages((prev) => {
  //               return {
  //                 ...prev,
  //                 [message?.conversation?.sid]: {
  //                   ...prev?.[message?.conversation?.sid],
  //                   messages: {
  //                     ...prev?.[message?.conversation?.sid]?.messages,
  //                     items: [
  //                       ...prev?.[message?.conversation?.sid]?.messages?.items,
  //                       message,
  //                     ],
  //                   },
  //                   unread_count:
  //                     message.state.author !== claims?.cont_id?.toString()
  //                       ? prev?.[message.conversation?.sid]?.unread_count + 1
  //                       : prev?.[message.conversation?.sid]?.unread_count,
  //                   body: message?.state?.body,
  //                   last_msg_author: message?.state?.author,
  //                   date: message?.state?.timestamp,
  //                   last_msg_media: all_urls?.length ? true : false,
  //                   msg_urls: {
  //                     ...prev?.[conversation?.sid]?.msg_urls,
  //                     [message?.state?.sid]: all_urls,
  //                   },
  //                 },
  //               }
  //             })
  //           })
  //         }
  //       })

  //       client.on('conversationLeft', (thisConversation) => {
  //         all_conversations = all_conversations.filter(
  //           (item) => item?.sid !== thisConversation?.sid
  //         )
  //         saveConversations((prev) => [
  //           ...prev?.filter((item) => item?.sid !== thisConversation?.sid),
  //         ])
  //         // if (thisConversation?.attributes?.campaign) {
  //         //   saveCampaigns((prev) => ({
  //         //     ...prev,
  //         //     [thisConversation?.attributes?.campaign]: {
  //         //       ...prev?.[thisConversation?.attributes?.campaign],
  //         //       conversations: [
  //         //         ...prev?.[
  //         //           thisConversation?.attributes?.campaign
  //         //         ].conversations?.filter(
  //         //           (item) => item.sid !== thisConversation?.sid
  //         //         ),
  //         //       ],
  //         //     },
  //         //   }))
  //         // }
  //       })
  //     }
  //   }
  //   init_conversations()

  //   return () => {
  //     controller?.abort()
  //   }
  //   // eslint-disable-next-line
  // }, [reloadConvs])

  useEffect(() => {
    const controller = new AbortController()
    const get_medias = async () => {
      if (!distribute?.content?.facebook?.pages) {
        const pages = await axiomFetch(
          '/social/facebook/pages',
          'GET',
          {},
          {},
          false,
          controller.signal
        )
        if (pages?.length) {
          const active_page = pages?.filter(
            (item) => item?.page_type === 'active'
          )?.[0]
          const active_instagram = pages.filter(
            (item) => item?.instagram_type === 'active'
          )?.[0]
          saveDistribute((prev) => ({
            ...prev,
            content: {
              ...prev?.content,
              facebook: {
                ...(prev?.content?.facebook || {}),
                pages,
                selected_page_id: active_page?.page_id,
              },
              instagram: {
                ...(prev?.content?.instagram || {}),
                pages,
                selected_instagram_id: active_instagram?.instagram_id,
                selected_page_id: active_instagram?.page_id,
              },
            },
          }))
        }
      }
    }
    get_medias()

    return () => {
      controller?.abort()
    }

    // eslint-disable-next-line
  }, [])

  if (!claims?.is_internal) {
    return (
      <>
        <div className='h-full min-h-[100vh] flex flex-col .scrollbar-hide font-base text-sm text-gray-500 rounded-2xl max-w-[375px] mx-auto bg-user-wrapper relative p-3 bg-white '>
          <SmallLoading styles={'mb-[400px]'} />
        </div>
      </>
    )
  }

  return (
    <div className='h-full min-h-[100vh] flex flex-col scrollbar-hide font-base text-sm text-gray-500 rounded-2xl max-w-[375px] mx-auto bg-user-wrapper relative p-[10px]'>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </div>
  )
}
