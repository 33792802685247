import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export const DocumentsContext = createContext()

export const DocumentsProvider = ({ children }) => {
  const [fileid, setFile] = useState(null)
  const [documents, setDocuments] = useState(null)

  const saveDocuments = useCallback(
    (data) => {
      setDocuments(data)
    },
    [setDocuments]
  )

  const saveFileID = useCallback(
    (id) => {
      setFile(id)
    },
    [setFile]
  )

  const getDocumentByID = useCallback(
    (id) => {
      if (!documents) {
        return null
      }
      return documents.find((item, index) => {
        return +item.FILE_DOCUMENT_ID === +id
      })
    },

    [documents]
  )

  const value = useMemo(
    () => ({
      fileid,
      saveFileID,
      documents,
      saveDocuments,

      getDocumentByID,
    }),
    [fileid, saveFileID, documents, saveDocuments, getDocumentByID]
  )

  return (
    <DocumentsContext.Provider value={value}>
      {children}
    </DocumentsContext.Provider>
  )
}

export const useDocuments = () => {
  const context = useContext(DocumentsContext)

  if (!context) {
    throw Error(
      'DocumentsContext must be used inside an DocumentsContextProvider'
    )
  }

  return context
}
