import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { set_storage, validate_number } from '../helpers'
import useAxiomFetch from '../hooks/useAxiomFetch'

export const AiDaily = createContext()

export const AiDailyProvider = ({ children, userData }) => {
  const { axiomFetch } = useAxiomFetch()
  const [aiDaily, setAiDaily] = useState(null)

  const saveAiDaily = useCallback((data) => {
    setAiDaily(data)
  }, [])

  const get_cat_tot_quest = (cat_item) => {
    const total_questions = cat_item?.questions?.length || 0
    return validate_number(total_questions)
  }

  const get_tot_quest = () => {
    return validate_number(
      aiDaily?.data?.bio_cat?.reduce(
        (acc, val) => acc + validate_number(val?.questions?.length),
        0
      )
    )
  }
  const get_cat_tot_ans = (cat_item) => {
    const total_answers = cat_item?.questions?.filter(
      (item) => item?.quest_ans
    )?.length
    return validate_number(total_answers)
  }

  const get_tot_ans = () => {
    return validate_number(
      aiDaily?.data?.bio_cat?.reduce(
        (acc, val) =>
          acc +
          validate_number(
            val?.questions?.filter((item) => item?.quest_ans)?.length
          ),
        0
      )
    )
  }

  const get_cat_per_ans = (cat_item) => {
    const percentage =
      validate_number(
        get_cat_tot_ans(cat_item) / get_cat_tot_quest(cat_item),
        2
      ) * 100
    return percentage
  }

  const get_tot_per = () => {
    const percentage = validate_number(get_tot_ans() / get_tot_quest(), 2) * 100
    return percentage
  }

  const set_quest_ans = (quest_item) => {
    setAiDaily((prev) => {
      const curr_cat = prev?.data?.bio_cat?.find(
        (item) => item?.cat_id === quest_item?.bio_cat_id
      )
      const result = {
        ...prev,
        data: {
          ...prev?.data,
          bio_cat: [
            ...prev?.data?.bio_cat?.filter(
              (item) => item?.cat_id !== quest_item?.bio_cat_id
            ),
            {
              ...curr_cat,
              questions: [
                ...curr_cat?.questions?.filter(
                  (item) => item?.quest_id !== quest_item?.quest_id
                ),
                quest_item,
              ]?.sort(
                (a, b) =>
                  validate_number(a?.quest_order) -
                  validate_number(b?.quest_order)
              ),
            },
          ]?.sort(
            (a, b) =>
              validate_number(a?.cat_order) - validate_number(b?.cat_order)
          ),
        },
      }
      set_storage('ai_daily', result)
      return result
    })
  }

  const set_quest = (quest_item) => {
    setAiDaily((prev) => {
      const curr_cat = prev?.data?.bio_cat?.find(
        (item) => item?.cat_id === quest_item?.bio_cat_id
      )
      const result = {
        ...prev,
        data: {
          ...prev?.data,
          bio_cat: [
            ...prev?.data?.bio_cat?.filter(
              (item) => item?.cat_id !== quest_item?.bio_cat_id
            ),
            {
              ...curr_cat,
              current_question: quest_item,
            },
          ]?.sort(
            (a, b) =>
              validate_number(a?.cat_order) - validate_number(b?.cat_order)
          ),
        },
      }
      set_storage('ai_daily', result)
      return result
    })
  }

  const upd_daily_signup = (signup_item) => {
    setAiDaily((prev) => {
      const result = {
        ...prev,
        data: {
          ...prev?.data,
          ai_prompts_signup: {
            ...prev?.data?.ai_prompts_signup,
            ...signup_item,
          },
        },
      }
      set_storage('ai_daily', result)
      return result
    })
  }

  const upd_quest_ans = async (data) => {
    await axiomFetch(
      `/marketing/content/ai/daily/ans`,
      'POST',
      data,
      {},
      false,
      null,
      400
    )
  }

  const save_resp = (post) => {
    saveAiDaily((prev) => {
      const result = {
        ...prev,
        data: {
          ...prev?.data,
          ai_prompts_daily_resp: [post, ...prev?.data?.ai_prompts_daily_resp],
        },
      }
      set_storage('ai_daily', result)
      return result
    })
  }
  const gen_daily_resp = async () => {
    const todays_prompt = aiDaily?.post?.content || {}
    const post = await axiomFetch(
      `/marketing/content/ai/daily/prompt_by_id`,
      'POST',
      {
        prompt_id:
          todays_prompt?.ai_prompts_daily_id ||
          validate_number(new Date().getDate()),
      }
    )
    return post
  }

  const value = useMemo(
    () => ({
      aiDaily,
      saveAiDaily,
      get_cat_tot_quest,
      get_cat_tot_ans,
      get_cat_per_ans,
      set_quest_ans,
      set_quest,
      get_tot_ans,
      get_tot_quest,
      get_tot_per,
      upd_quest_ans,
      upd_daily_signup,
      gen_daily_resp,
      save_resp,
    }),
    [aiDaily, saveAiDaily]
  )

  return <AiDaily.Provider value={value}>{children}</AiDaily.Provider>
}

export const useAiDaily = () => {
  const context = useContext(AiDaily)

  if (!context) {
    throw Error('AuthContext must be used inside an AuthContextProvider')
  }

  return context
}
