import IconMenu from '../assets/images/menuItem1.svg';
import IconMenu2 from '../assets/images/menuItem1.svg';
import ListItem1 from '../assets/images/item-1.jpg';
import ListItem2 from '../assets/images/item-2.jpg';
import ListItem3 from '../assets/images/item-3.jpg';
import ListItem4 from '../assets/images/item-4.jpg';
import ListItem5 from '../assets/images/item-5.jpg';
import ListItem6 from '../assets/images/item-6.jpg';
// import ListItem7 from '../assets/images/Zack.jpg'
import content from '../assets/images/marketing_lobby/content.png'
import guides from '../assets/images/marketing_lobby/guides.png'
import listings from '../assets/images/marketing_lobby/listings.png'
import mymarketing from '../assets/images/marketing_lobby/mymarketing.png'
import reviews from '../assets/images/marketing_lobby/reviews.png'
import schedule from '../assets/images/marketing_lobby/schedule.png'
import vbc from '../assets/images/marketing_lobby/vbc.png'
import zach from '../assets/images/marketing_lobby/Zach.png'
import axiomLogo from '../assets/images/axiom_driggs_logo.svg'
import marketing_conv from '../assets/images/conversations/marketing_conv.svg';
import vbc_images from '../assets/images/vbc';
import images_logos from '../assets/images/axiom_logos';
import images_tours from '../assets/images/listing_tours';
import { NavButton } from '../components/helpers';
export const USERMENU = [
  {
    title: 'Configure Your Profile',
    description: 'Fine-tune your AXIOM Profile here',
    link: '/user/my-profile',
    icon: IconMenu2,
  },
  {
    title: 'Configure Defaults',
    description: 'Configure Lead defaults and other default settings',
    link: '/user/defaults',
    icon: IconMenu2,
  },
  {
    title: 'Configure Automations',
    description: 'Configure Automations such as weekenders',
    link: '/user/automations',
    icon: IconMenu2,
  },
  {
    title: 'Send Us Feedback',
    description: 'Let us know how your experience with Axiom is going.',
    link: '/user/feedback',
    icon: IconMenu2,
  },
  // {
  //   title: 'Analytics',
  //   description: 'See the numbers for your content creation in Axiom',
  //   link: '/user/analytics',
  //   icon: IconMenu,
  // },
  {
    title: 'Billing',
    description: 'configure your payment methods in Axiom',
    link: '/user/billing',
    icon: IconMenu,
  },
  {
    title: 'Axiom Videos',
    description: 'Watch Axiom videos on how to get connected with Axiom',
    link: '/user/tutorials',
    icon: IconMenu2,
  },
  // {
  //   title: 'Configure AXIOM AI Creator',
  //   description: 'Configure your AXIOM AI content creator',
  //   link: '/user/axiom-ai',
  //   icon: IconMenu2,
  // },
  // {
  //   title: 'Configure Your Lead Lists',
  //   description: 'Configure your lists like NOD’s, FISBO’s and more',
  //   link: '/user/settings/lead-list',
  //   icon: IconMenu2,
  // },
  // {
  //   title: 'Configure Your Review Shares',
  //   description: 'Configure your Review share schedule',
  //   link: '/user/review-shares',
  //   icon: IconMenu2,
  // },
  // {
  //   title: 'Configure Your Active Listings',
  //   description: 'Configure your Active Listings',
  //   link: '/user/active-listing',
  //   icon: IconMenu2,
  // },
  // {
  //   title: 'Configure Your Call Center',
  //   description: 'Configure how you route calls.',
  //   link: '/user/settings/call-team',
  //   icon: IconMenu2,
  // },
  // {
  //   title: 'DRIGGS Marketing Tools',
  //   description: 'Utilize all of your innovative Marketing Tools ',
  //   link: '/user/marketing-tools',
  //   icon: IconMenu2,
  // },
  // {
  //   title: 'Settings & Support',
  //   description: 'Utilize all of your innovative Marketing Tools ',
  //   link: '/user/settings',
  //   icon: IconMenu2,
  // },
  {
    title: 'Connected Accounts',
    description: 'Reconfigure your connected Accounts.',
    link: '/user/settings/connected-accounts',
    icon: IconMenu2,
  },
];

// console.log('window', window.location.origin, window.location.origin === 'https://app.theaxiomapp.com')
export const NAVMENU = [
  {
    title: 'AXIOM',
    subtitle: "Your home page.",
    icon: IconMenu,
    link: '/user',
    id: 'home',
    internal: true,
  },
  {
    title: 'Marketing',
    subtitle: 'Your marketing in one place.',
    icon: IconMenu,
    link: '/user/marketing-lobby',
    id: 'marketing'
  },
  // {
  //   title: 'Axiom Ai Assistant',
  //   subtitle: 'Create the best content with our genius AI Content Creator.',
  //   icon: IconMenu,
  //   link: '/user/ai/assistant',
  //   id: 'ai_assistant'
  // },
  {
    title: 'Leads',
    subtitle: 'Manage your leads & lists.',
    icon: IconMenu,
    link: '/user/my-leads-lobby',
    id: 'leads'
  },
  {
    title: 'Closing Experiences',
    subtitle: 'All your files.',
    icon: IconMenu,
    link: '/user/my-transactions-lobby',
    id: 'transactions'
  },
  {
    title: 'AXIOM Estimators',
    subtitle: 'Calculate Property Estimates',
    icon: IconMenu,
    link: '/user/calculators',
    id: 'calculators',
    internal_only: false,
    internal: true
  },
  {
    title: 'Pairwise',
    subtitle: 'Manage your pairwise.',
    icon: IconMenu,
    link: '/user/pairwise',
    id: 'pairwise',
    internal_only: false,
    internal: false,
  },
  {
    title: 'Settings & Analytics',
    subtitle: 'settings, data, and feedback.',
    link: '/user/settings',
    icon: IconMenu,
    id: 'settings',
    internal: true,
  },
  // {
  //   title: 'Events',
  //   subtitle: 'Discover, Connect, Celebrate: Your Gateway to Local Events',
  //   link: '/user/events',
  //   icon: IconMenu,
  //   id: 'settings',

  // }

];



export const CALCMENU = [
  {
    title: 'Home Purchase',
    content: "Calculate your home purchase.",
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1708712586238.jpeg',
    link: '/user/calculators/buy',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 1,
    internal: true,
    completed: true,
  },
  {
    title: 'Home Sell',
    content: 'Calculate your net at close.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1708712611131.jpeg',
    link: '/user/calculators/sell',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 2,
    completed: true,

  },
  {
    title: 'Extra Payment',
    content: 'Calculate how much you save with an extra payment.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1709928448576.jpeg',
    link: '/user/calculators/extra_payment',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 21,
    completed: true,

  },
  {
    title: 'Loan Balance',
    content: 'Calculate how much you have paid off on your loan.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1709929097436.jpeg',
    link: '/user/calculators/loan_balance',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 63,
    completed: true,

  },
  {
    title: 'Loan Compare',
    content: 'Calculate which loan is best for you.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1710172168607.jpeg',
    link: '/user/calculators/loan_compare',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 41,
    completed: true,

  },
  {
    title: 'Title Fees',
    content: 'Calculate which loan is best for you.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1710886683123.jpeg',
    link: '/user/calculators/title_fees',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 44,
    completed: true,

  },
  {
    title: 'Loan Details',
    content: 'Calculate the loan details for a potential buyer',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1710185493869.jpeg',
    link: '/user/calculators/loan_details',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 61,
    completed: true,

  },
  {
    title: 'Holding Costs',
    content: 'Calculate how much you save by selling fast.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1710176393039.jpeg',
    link: '/user/calculators/holding_costs',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 62,
    completed: true,

  },
  {
    title: 'Multiple Offers',
    content: 'Find which offer is best for you to accept.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1710182867551.jpeg',
    link: '/user/calculators/multiple_offers',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 101,
    completed: true,

  },
  {
    title: 'Sell To Net',
    content: 'Calculate the sale price you need to net the amount you want.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1710189166301.jpeg',
    link: '/user/calculators/sell_to_net',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 122,
    completed: true,

  },
  {
    title: 'Affordability Calculator',
    content: 'Calculate the PITI that you can afford.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1710273562279.jpeg',
    link: '/user/calculators/affordability',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 43,
    completed: true,

  },
  {
    title: 'Rent Vs Buy',
    content: 'Rent Vs Buy',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1711568765303.jpeg',
    link: '/user/calculators/rent_vs_buy',
    swipe_right: function () {
      return <div>
        <NavButton title='New' link={this.link} />
      </div>
    },
    id: 121,
    completed: true,

  },


];

export const INNERMENU = {
  Detail: { label: 'My Details', link: '/user/my-details' },
  Team: { label: 'My Team', link: '/user/my-team' },
  VBC: { label: 'Realty Smart Business Card', link: '/user/virtual-cards' },
  AI: { label: 'AXIOM Ai', link: '/user/axiom-ai' },
  Engage: { label: 'Engage', link: '/user/engage' },
  RSS: { label: 'RSS', link: '/user/rss-feed' },
  Promotions: { label: 'Promotions', link: '/user/promotions' },
  Subscription: { label: 'My Subscription', link: '/user/my-subscription' },
  Billing: { label: 'My Billing', link: '/user/my-billing' },
  Analytics: { label: 'Analytics', link: '/user/analytics' },
  MarketingMaterial: {
    label: 'Marketing Material',
    link: '/user/marketing-material',
  },
  ApproveMM: {
    label: 'Approve Marketing Material',
    link: '/user/approve-marketing-material',
  },
  MyVBC: {
    label: `My Realty Smart Business Cards`,
    link: '/user/my-vbc',
  },
  Guide: {
    label: `Guides`,
    link: '/user/guides',
  },
  Reviews: {
    label: `My Reviews`,
    link: '/user/reviews',
  },
  MyListings: {
    label: `My Listings`,
    link: '/user/my-listing',
  },
  MySlyDials: {
    label: `My SlyDials`,
    link: '/user/my-slydials',
  },
  Transactionslobby: {
    label: `Transactions`,
    link: '/user/my-transactions-lobby',
  },
};

export const AVAILABLE_SLOTS = [
  {
    label: 'My New Listing',
    value: 'new list',
  },
  {
    label: 'My New Review',
    value: 'new review',
  },
  {
    label: 'Engage News & Articles',
    value: 'news articles',
  },
  {
    label: 'AXIOM Ai Content',
    value: 'ai content',
  },
  {
    label: 'A Promotion',
    value: 'a promotion',
  },
  {
    label: 'An RSS Feed',
    value: 'feed',
  },
];

export const SLOTSELECTORS = [
  { label: '4 Slots', value: 4 },
  { label: '8 Slots', value: 8 },
  { label: '10 Slots', value: 10 },
];

export const DUMMYMARKETINGLOBBY = [
  {
    title: 'Realty Smart Business Cards',
    description:
      'Create and share Realty Smart Business Cards to connect with clients and expand your network.',
    thumbnail: vbc,
    link: '/user/my-vbc',
    id: 'vbc'
  },
  {
    title: 'Marketing Materials',
    description:
      'Get organized & manage all your marketing in one place!',
    thumbnail: mymarketing,
    link: '/user/marketing-material',
    id: 'my_marketing'
  },

  // {
  //   title: 'Conversations Lobby',
  //   description:
  //     'Create and send your SMS messages and communicate directly with your prospects & clients!',
  //   thumbnail: marketing_conv,
  //   link: '/user/conversations',
  //   id: 'conversations',
  //   notifications: true
  // },
  // {
  //   title: 'AXIOM Content',
  //   description:
  //     'We make it simple for you to create and share high-quality, engaging content that converts.',
  //   thumbnail: content,
  //   link: '/user/axiom-ai',
  //   id: 'axiom_content'
  // },
  {
    title: 'Axiom Ai Assistant',
    description: 'Create the best content with our genius AI Content Creator.',
    thumbnail: content,
    link: '/user/ai/assistant',
    id: 'ai_assistant'
  },
  // {
  //   title: 'AI Daily Post',
  //   description: 'Get Daily posts and optimize AI specifically to you.',
  //   thumbnail: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1727797198032.jpeg',
  //   link: '/user/ai/daily',
  //   id: 'ai_daily'
  // },
  {
    title: 'Axiom Tours',
    description: 'Create a tour of homes. Find homes and optimize your route.',
    thumbnail: images_tours.buyer_tours_van,
    link: '/user/buyer_tours',
    id: 'buyer_tours'
  },
  {
    title: 'Social Media Posts',
    description: 'Update and View your pasts posts.',
    thumbnail: ListItem1,
    link: '/user/ai/media_posts',
    id: 'media_posts'
  },
  {
    title: 'Social Media Scheduler',
    description:
      'Harnessing the power of AXIOM to schedule and repurpose your content.',
    thumbnail: schedule,
    link: '/user/axiom-ai-scheduler',
    id: 'content_scheduler'
  },
  // {
  //   title: 'My Reviews',
  //   description:
  //     'We capture your client reviews so you can blast past the herd and make more sales. Reviews give you the edge.',
  //   thumbnail: reviews,
  //   link: '/user/reviews',
  //   id: 'my_reviews'
  // },
  // {
  //   title: 'Guides',
  //   description:
  //     'We offer comprehensive guides to walk your clients through the buying and selling process. Send them digitally or custom printed.',
  //   thumbnail: guides,
  //   link: '/user/guides',
  // }, 
  // {
  //   title: 'Listings',
  //   description:
  //     'Your current listings in one place! Order marketing from your listings now and close deals faster!',
  //   thumbnail: listings,
  //   link: '/user/my-listing',
  //   id: 'active_listings'
  // },
  // {
  //   title: 'My Promotions',
  //   description:
  //     'We give you the ability to create custom promotions to showcase on your virtual business cards and attract more clients.',
  //   thumbnail: ListItem6,
  //   link: '/user/promotions',
  // },
  // {
  //   title: 'Marketing for Approval',
  //   description:
  //     'Our app allows you to approve your custom marketing requests and ensure they align with your branding and goals.',
  //   thumbnail: ListItem4,
  //   link: '/user/approve-marketing-material',
  // },
  // {
  //   title: 'Events',
  //   description: 'Discover, Connect, Celebrate: Your Gateway to Local Events',
  //   link: '/user/events',
  //   thumbnail: vbc_images.events,
  //   id: 'settings',

  // },
  {
    title: 'Your Marketing Rep',
    description: '{{name}} is your Marketing Rep. They can help you answer questions about Marketing and our other services.',
    link_title: 'Call Me Now!',
    thumbnail: zach,
    link: '/user/marketing-call',
    id: 'market_rep'
  },

];

export const PAIRWISELOBBY = [
  {
    id: "pairwise_eligibility",
    title: 'Pairwise Eligibility',
    content:
      'See if you qualify to be in a pairwise.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1712865135786.jpeg',
    link: '/user/pairwise/estimator',
    swipe_right: function () {
      return <div>
        <NavButton className='text-[18px]' title='View' link={this.link} />
      </div>
    },
    type: 'out_pairwise'
  },
  // {
  //   id: "pairwise_estimator",
  //   title: 'Pairwise Estimator',
  //   content:
  //     'Estimate your pairwise earnings.',
  //   image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1712865818597.jpeg',
  //   link: '/user/pairwise/estimator',
  //   swipe_right: function () {
  //     return <div>
  //       <NavButton className='text-[18px]' title='View' link={this.link} />
  //     </div>
  //   },
  //   type: 'out_pairwise'
  // },
  {
    id: "pairwise_documents",
    title: 'Pairwise Documents',
    content:
      'Fill out your forms and join a pairwise.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1712865898014.jpeg',
    link: '/user/pairwise/documents/sign',
    swipe_right: function () {
      return <div>
        <NavButton className='text-[18px]' title='View' link={this.link} />
      </div>
    },
    type: 'out_pairwise'
  },
  {
    id: "pairwise_payment",
    title: 'Pairwise Payments',
    content:
      'Send a pairwise payment.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1712865962702.jpeg',
    link: '/user/pairwise/payments',
    swipe_right: function () {
      return <div>
        <NavButton className='text-[18px]' title='View' link={this.link} />
      </div>
    },
    type: 'out_pairwise'
  },
  {
    id: "pulse_report",
    title: 'Pairwise Pulse Report',
    content:
      'See how your Pairwise group is doing.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1712865228175.jpeg',
    link: '/user/pairwise/pulse-report',
    swipe_right: function () {
      return <div>
        <NavButton className='text-[18px]' title='View' link={this.link} />
      </div>
    },
    type: 'in_pairwise'
  },
  {
    id: "pairwise_documents_view",
    title: 'Pairwise Documents',
    content:
      'View your pairwise documents.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1712866075626.jpeg',
    //'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1712866183833.jpeg',
    //
    link: '/user/pairwise/documents/view',
    swipe_right: function () {
      return <div>
        <NavButton className='text-[18px]' title='View' link={this.link} />
      </div>
    },
    type: 'in_pairwise'
  },
  {
    id: "pairwise_disclosure_form",
    title: 'Pairwise Disclosure Form',
    content:
      'Access your disclosure form.',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1712866415033.jpeg',
    link: '/user/pairwise/disclosure-form',
    swipe_right: function () {
      return <div>
        <NavButton className='text-[18px]' title='View' link={this.link} />
      </div>
    },
    type: 'in_pairwise'
  },
  {
    id: "distributions",
    title: 'Distributions',
    content:
      'View Your Past Distributions',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1713913009025.jpeg',
    link: '/user/pairwise/distributions',
    swipe_right: function () {
      return <div>
        <NavButton className='text-[18px]' title='View' link={this.link} />
      </div>
    },
    type: 'in_pairwise'
  },
  {
    id: "k1",
    title: 'K1s',
    content:
      'View your K1s',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1713913225299.jpeg',
    link: '/user/pairwise/k1s',
    swipe_right: function () {
      return <div>
        <NavButton className='text-[18px]' title='View' link={this.link} />
      </div>
    },
    type: 'in_pairwise'
  },
  {
    id: "account",
    title: 'Account',
    content:
      'View and update your bank account. ',
    image: 'https://driggstitle.com/reviews/node/node_to_oracle/a_file_1726606841620.jpeg',
    link: '/user/pairwise/account',
    swipe_right: function () {
      return <div>
        <NavButton className='text-[18px]' title='View' link={this.link} />
      </div>
    },
    type: 'in_pairwise'
  },
]

export const DUMMYMARKETMATERIAL = [
  {
    title: 'Order Listing Kit',
    description:
      'Easily order a professional Property Listing Kit through for a polished presentation to potential sellers.',
    thumbnail: ListItem1,
  },
  {
    title: 'Order Open House Kit',
    description:
      'Order a complete Open House Kit for a successful and organized event.',
    thumbnail: ListItem2,
  },
  {
    title: 'Order Sign Riders',
    description:
      "Order customizable Property Sign Riders through to enhance your listing's visibility.",
    thumbnail: ListItem3,
  },
  {
    title: 'Order Business Cards',
    description:
      'Design and order professional Business Cards to leave a lasting impression.',
    thumbnail: ListItem4,
  },
  {
    title: 'Order Holiday cards',
    description:
      "Order custom-designed Holiday Cards to send season's greetings to clients.",
    thumbnail: ListItem5,
  },
  {
    title: 'Order Postcards',
    description:
      'Order custom-designed Postcards to keep in touch with clients and potential leads.',
    thumbnail: ListItem6,
  },
  {
    title: 'Order Flyers',
    description:
      "Our app empowers you with real custom flyers that are so sharp and professional, they'll give your competition a run for their money.",
    thumbnail: ListItem4,
  },
];

export const DUMMYVBCS = [
  {
    title: 'Buyer Realty Smart Card',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem1,
  },
  {
    title: 'Seller VBC',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem2,
  },
  {
    title: 'Prospect VBC',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem3,
  },
];

export const DUMMYGUIDES = [
  {
    title: 'Guide 1',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem1,
  },
  {
    title: 'Guide 2',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem2,
  },
  {
    title: 'Guide 3',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem3,
  },
  {
    title: 'Guide 4',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem4,
  },
  {
    title: 'Guide 5',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem5,
  },
  {
    title: 'Guide 6',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem6,
  },
  {
    title: 'Guide 7',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem4,
  },
];

export const DUMMYREVIEWS = [
  {
    title: 'Review 1',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem2,
  },
  {
    title: 'Review 2',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem2,
  },
  {
    title: 'Review 3',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem2,
  },
  {
    title: 'Review 4',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem2,
  },
  {
    title: 'Review 5',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem2,
  },
  {
    title: 'Review 6',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem2,
  },
  {
    title: 'Review 7',
    description:
      'Lorem ipsum dolor sit amet consectetur. Tincidunt leo tincidunt donec arcu tellus. Tincidunt donec arcu tellus.',
    thumbnail: ListItem2,
  },
];

export const DUMMYTHUMBS = [ListItem1, ListItem2, ListItem3];

export const Videos = [
  {
    title: 'Axiom Intro Video',
    description: "Let's get you connected with AXIOM!",
    url: 'https://youtu.be/z8W3KgcHJ_c',
    thumbnail: images_logos.axiom_small_png,
    unique_name: 'axiom_intro_video'
  },
  {
    title: 'Axiom connect to facebook',
    description:
      'A step by step video on how to connect Axiom to Facebook',
    url: 'https://youtu.be/yXnel5E-K4A',
    thumbnail: content,
    unique_name: 'axiom_connect_facebook'
  },
]

export const content_types = [
  'facebook',
  'linkedin',
  'twitter',
  'instagram',
  'text',
  'email',
  'blog',
  'letter',
]

export const social_medias = ['facebook', 'linkedin', 'twitter', 'instagram']

export const long_types = ['blog', 'letter', 'email']